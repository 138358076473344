<template>
  <v-main>
    <v-app-bar
      class="ukpl-main-navigation"
      v-if="currentUser"
      dense
      color="#0072ff"
      dark
      elevate-on-scroll
    >
      <div class="ukpl-main-navigation-container">
        <div class="navigation-left-wrap">
          <a href="https://www.ukpl.co.uk">
            <v-img
              src="@/assets/ukpl-logo-new.png"
              alt="UKPL logo"
              contain
              class="ukpl-nav-logo"
            />
          </a>
        </div>
        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn v-if="isAdmin" color="#0072ff" depressed to="/admin"
            >admin</v-btn
          >
          <v-btn color="#0072ff" depressed to="/team"
            ><v-icon class="mr-2">fa-users</v-icon>Teams</v-btn
          >
          <v-btn v-if="currentCaptain" color="#0072ff" depressed to="/roster"
            ><v-icon class="mr-2">fa-list</v-icon>Roster</v-btn
          >
          <v-btn color="#0072ff" depressed to="/profile"
            ><v-icon class="mr-2">fa-user</v-icon>Profile</v-btn
          >
          <v-btn color="#0072ff" depressed to="/disclaimer"
            ><v-icon class="mr-2">fa-file-signature</v-icon>Disclaimer</v-btn
          >
          <v-btn color="#0072ff" depressed to="/idcard"
            ><v-icon class="mr-2">far fa-id-card</v-icon>ID Card</v-btn
          >
          <v-btn color="#0072ff" depressed @click.native="logOut"
            ><v-icon class="mr-2">fa-sign-out-alt</v-icon>LogOut</v-btn
          >
        </v-toolbar-items>
      </div>

      <v-toolbar-items class="hidden-md-and-up">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-if="isAdmin" key="admin" to="/admin">
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item>
          <v-list-item key="team" to="/team">
            <v-list-item-icon><v-icon>fa-users</v-icon></v-list-item-icon>
            <v-list-item-title>Teams</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="currentCaptain" key="roster" to="/roster">
            <v-list-item-icon><v-icon>fa-list</v-icon></v-list-item-icon>
            <v-list-item-title>Roster</v-list-item-title>
          </v-list-item>
          <v-list-item key="profile" to="/profile">
            <v-list-item-icon><v-icon>fa-user</v-icon></v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item key="disclaimer" to="/disclaimer">
            <v-list-item-icon
              ><v-icon>fa-file-signature</v-icon></v-list-item-icon
            >
            <v-list-item-title>Disclaimer</v-list-item-title>
          </v-list-item>
          <v-list-item key="id-card" to="/idcard">
            <v-list-item-icon><v-icon>far fa-id-card</v-icon></v-list-item-icon>
            <v-list-item-title>ID Card</v-list-item-title>
          </v-list-item>
          <v-list-item @click.native="logOut" key="icon">
            <v-list-item-icon
              ><v-icon>fa-sign-out-alt</v-icon></v-list-item-icon
            >
            <v-list-item-title>LogOut</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-sheet
      id="scrolling-techniques-7"
      class="ukpl-blue-card-container"
      height="100%"
    >
      <v-container fluid height="100%">
        <v-row justify="center" align="center" class="row">
          <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
            <v-card>
              <v-card-text
                ><div>
                  Please note we are currently improving our Add Team capability
                  to unify your experience. Add teams will be available again
                  within the next couple of weeks. For now, if your team is not
                  listed, or is in the wrong division, please get your team
                  captain to reach out to us on facebook messenger and we'll
                  correct the data for you asap.
                </div></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
        <div class="profile-heading-wrap">
          <h2 class="profile-heading">Select your division</h2>
        </div>
        <v-row justify="center" align="center" class="row">
          <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
            <v-select
              v-model="select"
              :items="divisions"
              item-text="name"
              item-value="id"
              label="Division"
              return-object
              dense
              v-on:change="reloadTeams"
              class="ukpl-team-select"
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="row">
          <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
            <v-data-table
              :headers="headers"
              :items="teams"
              :items-per-page="-1"
              :loading="teamsLoading"
              class="ukpl-teams-table"
              item-key="id"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Team Management</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:item.logo="{ item }">
                <v-img :src="getUrl(item.logo)" max-width="40" />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-snackbar
      :timeout="2000"
      v-model="messageon"
      color="red accent-4"
      rounded="pill"
      elevation="24"
    >
      {{ message }}
    </v-snackbar>
    <v-snackbar
      :timeout="2000"
      v-model="savesuccess"
      color="green accent-4"
      rounded="pill"
      elevation="24"
    >
      Details Saved.
    </v-snackbar>
  </v-main>
</template>

<script>
import UserService from "../services/user.service";
import UserDetails from "../model/userdetails";
import DivisionService from "../services/division.service";
import TeamService from "../services/team.service";

export default {
  name: "Team",
  components: {},
  data: () => ({
    logo: undefined,
    currentCaptain: false,
    messageon: false,
    drawer: false,
    dialog: false,
    select: { id: "1", name: "Elite" },
    divisions: [],
    teams: [],
    headers: [
      { text: "#", align: "start", value: "id" },
      { text: "Team Name", value: "name" },
      { text: "Team Logo", value: "logo" },
    ],
    teamsLoading: true,
    editedItem: {
      id: "",
      name: "",
    },
    editedIndex: -1,
    defaultItem: {},
    savesuccess: "",
    savefail: "",
    message: "",
    userDetails: new UserDetails(),
    currentImage: undefined,
    previewImage: undefined,
    progress: 0,
    imageDimensions: {
      size: "",
      height: "",
      width: "",
    },
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }
      return false;
    },
  },
  methods: {
    isCaptain() {
      UserService.isTeamCaptain().then(
        (response) => {
          if (response.data) {
            this.currentCaptain = true;
          } else {
            this.currentCaptain = false;
          }
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getUrl(logo) {
      if (logo === null) {
        logo = "default-team-logo.png";
      }
      return "https://assets.ukpl.co.uk/thumbs/images/" + logo;
    },
    generateFileUrl(file) {
      if (file === undefined || file === null) {
        return "";
      } else {
        return "data:image/jpeg;base64," + file.data;
      }
    },
    selectImage(image) {
      const SIZE = 4194304; //4Mb (binary not decimal)
      //const WIDTH = 250;
      //const HEIGHT = 250;
      if (image != undefined && image != null) {
        this.imageDimensions.size = image.size;
        if (this.imageDimensions.size > SIZE) {
          this.message = `The image size is larger than 4Mb.`;
          this.currentImage = undefined;
          this.previewImage = undefined;
          this.logo = null;
          image = undefined;
          return false;
        }
        this.currentImage = image;
        this.previewImage = URL.createObjectURL(this.currentImage);
        this.progress = 0;
        //put size validation here, and if it fails, set the message and clear the currentImage/previewImage
        let reader = new FileReader();

        reader.readAsDataURL(this.currentImage);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            //this.imageDimensions.width = img.width;
            //this.imageDimensions.height = img.height;
            // if(this.imageDimensions.width != WIDTH) {
            //   this.message = `The image width is not 250px`;
            //   this.currentImage = undefined;
            //   this.previewImage = undefined;
            //   this.logo = undefined;
            //   return;
            // }
            // if(this.imageDimensions.height != HEIGHT) {
            //   this.imageError = `The image height is not 250px`;
            //   this.currentImage = undefined;
            //   this.previewImage = undefined;
            //   this.logo = undefined;
            //   return;
            // }
          };
          img.src = evt.target.result;
        };

        reader.onerror = (evt) => {
          console.error(evt);
        };
      }
      this.message = "";
    },
    reloadTeams(sel) {
      this.initializeTeams(sel.id);
    },
    logOut() {
      console.log("logging out");
      this.$store.dispatch("auth/logout");
      this.$router.push("/home");
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.currentImage = undefined;
        this.previewImage = undefined;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.message = "";
      });
    },
    save() {
      if (!this.currentImage) {
        this.message = "Please select an Image!";
        return;
      }
      this.teams.push(this.editedItem);
      TeamService.addTeamToDivision(
        this.editedItem,
        this.select.id,
        this.currentImage
      ).then(
        (response) => {
          this.teams = response.data;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.messageon = true;
        }
      );

      this.close();
    },
    initializeDiv() {
      DivisionService.getDivisions().then(
        (response) => {
          this.divisions = response.data;
          this.select = this.divisions[0];
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    initializeTeams(divId) {
      this.teamsLoading = true;
      TeamService.getTeamsForDivision(divId).then(
        (response) => {
          this.teams = response.data;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      this.teamsLoading = false;
    },
  },
  created() {
    this.isCaptain();
    this.initializeDiv();
    this.initializeTeams(this.select.id);
    UserService.getUserDetails().then(
      (response) => {
        this.userDetails.username = response.data.username;
        this.userDetails.email = response.data.email;
        this.userDetails.password = response.data.password;
        this.userDetails.firstname = response.data.firstname;
        this.userDetails.lastname = response.data.lastname;
        this.userDetails.gender = response.data.gender;
        this.userDetails.dateOfBirth = response.data.dateOfBirth;
        this.userDetails.contactNumber = response.data.contactNumber;
        this.userDetails.addressline1 = response.data.addressline1;
        this.userDetails.addressline2 = response.data.addressline2;
        this.userDetails.town = response.data.town;
        this.userDetails.county = response.data.county;
        this.userDetails.postcode = response.data.postcode;
        this.userDetails.file = response.data.file;
        this.userDetails.ukpsf = response.data.ukpsf;
        this.userDetails.teamcaptain = response.data.teamcaptain;
      },
      (error) => {
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  updated() {
    this.teamsLoading = false;
  },
  mounted() {},
};
</script>
